import { faCircleUser } from '@fortawesome/pro-light-svg-icons';
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cookies from 'js-cookie';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

import { cta, danger, fontLight, info } from '@constants/colors';
import { tablet } from '@constants/media-queries';
import { forgot, signup } from '@constants/routes';
import { space } from '@constants/spaces';
import Button from '@elements/Button';
import Input from '@elements/Input';
import Title from '@elements/Title';
import { SignInInput, useSignInMutation } from '@graphql/generated/graphql';

const Component = styled.div`
  margin: 0 auto;
  padding: ${space * 5}px ${space * 3}px;
  text-align: center;

  input {
    font-size: 16px;
  }

  ${tablet} {
    padding: ${space * 7}px ${space * 5}px;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${cta};
`;

const SignUp = styled.a`
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: ${info};
  margin-top: ${space}px;
  svg {
    margin-left: ${space}px;
  }
`;

const Forgot = styled.a`
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  text-decoration: none;
  color: ${fontLight};
`;

const ApiErrors = styled.div<{ show: boolean }>`
  font-size: 12px;
  color: ${danger};
  height: 14px;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const SignInForm = () => {
  const [signIn, { loading, error }] = useSignInMutation();
  const router = useRouter();
  const formMethods = useForm();
  const {
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods;

  const onSubmit: SubmitHandler<SignInInput> = async (data) => {
    // Same path with no dialog hash

    signIn({
      variables: { input: data },
      onCompleted: (data) => {
        //TODO: better way to get latest account data?
        Cookies.set('accessToken', data?.signIn?.accessToken);

        // Set onboarding for participant or co-owner
        localStorage.setItem('onboarding-collaborator', Date.now().toString());

        if (router.query.redirect) {
          router.push(router.query.redirect as string);
        } else {
          const newPath = router.asPath.replace(/#[\w-]+/g, '');
          router.push(newPath);
          router.reload(); // reloading to update user data
        }
      },
      onError: (error) => {
        setError('password', { type: 'api', message: error.message });
      },
    });
  };

  const apiErrorMessage = () => {
    switch (errors.password.message) {
      case 'UNAUTHENTICATED':
        return 'Je inloggegevens zijn onjuist';
      default:
        return 'Er is iets misgegaan';
    }
  };

  return (
    <Component>
      <Icon icon={faCircleUser} size="3x" />
      <Title size="nm" as="h3" className="mt-16">
        Log in met je account
      </Title>
      <Link href={signup} passHref>
        <SignUp>
          Ik heb nog geen account
          <FontAwesomeIcon icon={faArrowRightLong} />
        </SignUp>
      </Link>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input name="email" label="E-mailadres" className="mt-16" required />
          <Input
            name="password"
            label="Wachtwoord"
            type="password"
            className="mt-16"
            required
            showError={errors?.password?.type !== 'api'}
          />
          <Link href={forgot} passHref>
            <Forgot>Wachtwoord vergeten?</Forgot>
          </Link>
          <ApiErrors show={error!! && errors?.password}>
            {errors?.password && apiErrorMessage()}
          </ApiErrors>
          <Button
            loading={loading}
            type="submit"
            width="100%"
            className="mt-16"
            data-gtm="login"
          >
            Inloggen
          </Button>
        </form>
      </FormProvider>
    </Component>
  );
};

export default SignInForm;
